import styled from 'styled-components';
import AppColors from '../../../styles/colors';
import IconPdf from '../../../images/icon-pdf-doc.png';
import IconArrowGreenRight from '../../../images/icon-arrow-green-right.png';
export const MainContainer = styled.div`
  position: relative;
  font-family: 'Gotham', arial, sans-serif;
  font-size: 18px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.8);
  @media (max-width: 1399px) {
    font-size: 16px;
    line-height: 25px;
  }
  @media (max-width: 767px) {
    font-size: 15px;
    line-height: 22px;
  }
`;
export const HeaderH2 = styled.h2`
  text-align: ${(props) => (props.$textAlign ? 'center' : 'left')};
  position: relative;
  margin-right: 0px;
  margin-bottom: 40px;
  margin-left: 0px;
  padding: 0px 0px 20px;
  font-family: interstate, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 42px;
  line-height: 45px;
  color: rgb(31, 178, 90);
  margin-top: 50px !important;
  &.lessMargin {
    margin-bottom: 15px;
  }
  ::after {
    content: '';
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 200px;
    height: 4px;
    transition: all 0.2s ease-out;
    background: ${AppColors.backgroundBlack1};
  }
  @media (max-width: 1399px) {
    margin-top: 0px !important;
  }
  @media (max-width: 767px) {
    margin: 0px 0px 30px;
    font-size: 25px;
    line-height: 27px;
  }
`;
export const HeaderH3 = styled.h3`
  position: relative;
  margin: 0px 0px 40px;
  font-family: interstate, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 35px;
  line-height: 40px;
  color: rgb(0, 105, 170);
  @media (max-width: 767px) {
    margin: 0px 0px 30px;
    font-size: 20px;
    line-height: 22px;
  }
`;

export const BlockQuotes = styled.blockquote`
  position: relative;
  width: 100%;
  padding: 40px;
  margin: 0 0 80px 0;
  float: right;
  font-family: Gotham-Light, arial, sans-serif;
  font-size: 24px;
  background: ${AppColors.backgroundBlack};
  @media (max-width: 767px) {
    padding: 30px;
    margin: 0 0 30px 0;
  }
  :after {
    content: '';
    display: block;
    position: absolute;
    bottom: -12px;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 12px 18px 0 0px;
    border-color: ${AppColors.backgroundBlack} transparent transparent
      transparent;
  }
  p {
    font-family: 'Gotham-Light', arial, sans-serif;
    font-size: 24px;
    line-height: 38px;
    padding: 0px;
    color: rgba(0, 0, 0, 0.8);
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 24px;
    }
    &:last-child {
      margin-bottom: 0 !important;
    }
  }
`;

export const HeadingH4 = styled.h4`
  width: 100%;
  font-family: 'Gotham-Light', arial, sans-serif;
  font-size: 24px;
  line-height: 38px;
  margin-bottom: 0px !important;

  @media (max-width: 767px) {
    font-size: 19px !important;
    line-height: 27px !important;
  }
  i {
    font-family: 'Gotham-Regular', arial, sans-serif;
    font-style: italic;
    margin-bottom: 0;
    font-size: 16px;
    line-height: 24px;
    @media (max-width: 1399px) {
      font-size: 15px;
      line-height: 22px;
    }
    @media (max-width: 991px) {
      font-size: 14px;
      line-height: 22px;
    }
    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 22px;
    }
  }
  b {
    font-family: 'Gotham-Bold', arial, sans-serif;
    font-weight: normal;
    font-style: normal;
  }
`;

export const HeadingH5 = styled.h5`
  font-family: Gotham-Regular, arial, sans-serif !important;
  font-size: 17px !important;
  line-height: 32px !important;
  margin-bottom: 40px !important;
  margin-top: 40px !important;
  color: rgba(0, 0, 0, 0.8) !important;
  @media (max-width: 767px) {
    font-size: 15px !important;
    line-height: 23px !important;
  }
`;

export const HeadingH6 = styled.h6`
  font-family: Gotham-Regular, arial, sans-serif !important;
  font-size: 17px !important;
  line-height: 32px !important;
  margin-bottom: 40px !important;
  color: rgba(0, 0, 0, 0.8) !important;
  @media (max-width: 767px) {
    font-size: 15px !important;
    line-height: 23px !important;
  }
`;

export const ParaText = styled.p`
  width: 100%;
  font-family: 'Gotham-Light', arial, sans-serif;
  font-size: 17px;
  line-height: 32px;
  margin-bottom: 0px !important;
  @media (max-width: 767px) {
    font-size: 19px !important;
    line-height: 27px !important;
  }
  i {
    font-family: 'Gotham-Regular', arial, sans-serif;
    font-style: italic;
    margin-bottom: 0;
    font-size: 14px;
    line-height: 22px;
    @media (max-width: 1399px) {
      font-size: 15px;
      line-height: 22px;
    }
    @media (max-width: 991px) {
      font-size: 14px;
      line-height: 22px;
    }
    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 22px;
    }
  }
  b {
    font-family: 'Gotham-Bold', arial, sans-serif;
    font-weight: normal;
    font-style: normal;
  }
`;

export const ImageStyle = styled.img`
  display: block;
  border: 0px;
  vertical-align: top;
  margin-bottom: 40px;
  width: 100% !important;
  height: auto !important;
  @media (max-width: 991px) {
    width: 100%;
  }
`;
export const SuperScriptStyle = styled.sup`
  vertical-align: top;
  font-size: 18px;
  line-height: 30px;
`;
export const UnorderedList = styled.ul`
  margin: 0px !important;
  padding: 0;
`;
export const ListItem = styled.li`
  padding: 10px 0px 10px 30px;
  background: url(${IconArrowGreenRight}) 0px 19px / 10px no-repeat;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  list-style: none;
  margin: 0;
  &:last-child {
    border-bottom: 0px !important;
  }
  p {
    margin: 0;
  }
`;
export const ListItemArrow = styled.li`
  margin: 0px;
  padding: 10px 0px 10px 30px;
  list-style: none;
  background: url(${IconArrowGreenRight}) 0px 19px / 10px no-repeat;
  p {
    margin: 0;
  }
  @media (max-width: 767px) {
    background-position: 0 5px;
  }
`;

export const LinkStyle = styled.a`
  text-decoration: none;
  color: #55aa4c;
  font-size: 17px !important;
  line-height: 32px;
  transition: 0.25s ease-out;
  font-family: 'Gotham-Regular', arial, sans-serif !important;
  code {
    font-family: 'Gotham-Regular', arial, sans-serif !important;
    font-size: 17px !important;
    line-height: 32px !important;
    color: rgba(0, 0, 0, 0.8) !important;
    background-color: rgb(31, 178, 90);
    border-bottom: 4px solid rgb(0, 135, 82);
    text-decoration: none;
    :hover {
      background-color: #0397d6;
      border-bottom: 4px solid ${AppColors.BrightBlue};
    }
  }
  @media (max-width: 767px) {
    text-align: left;
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 0px;
    margin-right: 0px !important;
  }
`;
export const ListItemNumber = styled.li`
  position: relative;
  margin: 0;
  padding: 15px 0 15px 65px;
  p {
    margin: 0px;
  }
  @media (max-width: 767px) {
    padding-left: 50px;
  }
  ::before {
    content: counter(standard-counter);
    counter-increment: standard-counter;
    position: absolute;
    left: 0px;
    width: 40px;
    text-align: center;
    padding: 6px 0 0 0;
    font-family: 'Adelle-Bold', arial, sans-serif;
    font-size: 30px;
    line-height: 30px;
    color: #55aa4c;
    border: 2px solid #55aa4c;
    border-radius: 50%;

    @media (max-width: 767px) {
      top: 11px;
      width: 30px;
      height: 30px;
      padding: 3px 0 0 0;
      font-size: 20px;
      line-height: 20px;
    }
  }
`;

export const HorizontalBreak = styled.hr`
  height: 1px !important;
  margin: 0px !important;
  clear: both;
  background: rgba(0, 0, 0, 0.15);
  opacity: 1 !important;
`;

export const OrderListItem = styled.ol`
  margin: 0 0 30px 0;
  list-style-type: none;
  counter-reset: standard-counter;
  position: relative;
  padding: 0;
`;
